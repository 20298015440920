import './app.scss';
import "bootstrap/js/dist/base-component.js";
import "bootstrap/js/dist/collapse.js";
import "bootstrap/js/dist/dropdown.js";
//import 'mdb-ui-kit/js/mdb.min';

function setCookie(name,value,days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString() + ';domain=' + window.location.host.replace("dwww", '').replace("www", '');
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getParameterByName(name, url = undefined) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const r = getParameterByName('r');
if (r) {
  setCookie('wcr', r, 30)
}

const scid = getParameterByName('source_campaign');
if (scid && !window['getCookie']('wc-scid')) {
  setCookie('wc-scid', scid, 30)
  setCookie('wc-scid-promo', Date.now() + 3 * 24 * 60 * 60 * 1000, 3)
}

